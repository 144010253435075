// main js file

// Initiate baguetteBox lightbox
baguetteBox.run('.gallery', {
  noScrollbars: true,
  filter: new RegExp('.+\.(gif|jpe?g|png|webp|aspx)', 'i')
  });

// Initiate tooltipster
$('.hotspot').tooltipster({
  animation: 'fade',
  delay: 200,
  theme: ['tooltipster-shadow', 'shadow-custom'],
  interactive: true,
  contentCloning: true,
  trigger: 'custom',
  triggerOpen: {
    mouseenter: true,
    touchstart: true,
    click: true,
    tap: true
  },
  triggerClose: {
    mouseleave: true,
    originClick: true,
    touchleave: true,
    click: true,
    tap: true
  }
});
